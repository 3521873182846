import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTimes,
  faCalendarAlt,
  faSearch,
  faCheck,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { debounce } from "lodash";

import { Link, useNavigate } from "react-router-dom";
import King from "../../assets/images/king.png";
import Filter from "../../assets/images/filter-ico.svg";
import apiService from "../../core/apiService";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { REACT_APP_LIMIT } from "../../apiServices/apiServices";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
const AllAccounts = () => {
  const [isFilter, setisFilter] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [cookies] = useCookies(["userToken"]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState({
    search: "",
    start_date: "",
    promoName: "",
    end_date: "",
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const [restaurantList, setRestaurantList] = useState([]);

  const [promo, setPromo] = useState([]);
  const [modal, setModal] = useState({
    data: null,
  });

  const selector = useSelector((state) => state);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [restaurantEmail, setRestaurantEmail] = useState(true);
  const [defaultContent, setDefaultContent] = useState("");
  const [isEdited, setIsEdited] = useState(false); // Track if the editor state has been modified
  // Initialize editorState
  const blocksFromHTML = convertFromHTML(defaultContent);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );


  // Update defaultContent based on modal
  useEffect(() => {
    console.log(
      modal?.data?.restaurant_email,
      "modal?.data?.restaurant_email",
      modal
    );

    if (modal?.data?.restaurant_email) {
      const content = `
      <p>  <b>Hey ${modal?.data?.restaurant_name}! </b>One more step before you claim your account...</p>
      <p>Here are the details you need to log in to your restaurant dashboard:</p>
  
       <p> 1. Navigate to: https://restaurant.whatsonlogin.com/</p>
        
       <p> 2. Use your temporary login details below:</p>
          <br/> 
            <b>Username:</b> ${modal?.data?.user.username}
          <br/>
            <b>Password:</b> ${modal?.data?.user.plainpassword}
          <br/><br/>
     
         
       <p> 3. Navigate to your profile to change your username and password</p>
      <p> 4. Activate your subscription to unlock all premium features</p>
     
      <p>
        We’re thrilled to have you on board and can’t wait for you to experience all the ways WhatsOn helps you connect with customers and grow your business. Welcome to the future of customer engagement!
      </p>
    `;
    
      setDefaultContent(content);
    }
  }, [modal]);
  // Update editorState when defaultContent changes
  useEffect(() => {
    if (defaultContent) {
      
      const blocksFromHTML = convertFromHTML(defaultContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
    
   
      setEditorState(
        EditorState.createWithContent(contentState)

      );
      // Only set the default content if the editor has not been edited
      if (!isEdited) {
        // Parse the defaultContent as HTML to create ContentState
        const blocksFromHTML = convertFromHTML(defaultContent);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
      
        // Convert the modified content to raw and then to HTML
        const rawContent = convertToRaw(
          EditorState.createWithContent(contentState).getCurrentContent()
        );
        const htmlContent = draftToHtml(rawContent);
      
        console.log(htmlContent, "hdddddddddddg");
      
        // Set the value with the HTML content
        setValue("message", htmlContent);
      }
      
    }
  }, [defaultContent, isEdited]);
  const [refresh, setRefresh] = useState(false);

  const showFilter = () => {
    setisFilter(isFilter === false ? true : false);
    if (isFilter === true) return;
    setCurrentPage(1);
    apiService.get("/getPromos", {}, ({ err, data }) => {
      if (err) return console.log(err);

      setPromo(data.data.rows);
    });
  };
  const handleClose = () => {
    setShow(false);
    reset();
    setModal({});
    setRestaurantEmail(true)

  };
  const handleShow = (args) => {
    console.log(args, "argsargs");

    setModal(args || "");

    setShow(true);
  };
  const updateData = (page) => {
    setCurrentPage(page);
  };
  const handleSerach = (args) => {
    let params = {
      pageno: currentPage,
      search: appliedFilters.search,
      start_date: appliedFilters.start_date,
      end_date: appliedFilters.end_date,
      promoName: appliedFilters.promoName,
    };
    console.log(params, "params");
    if (args?.filters) {
      params = { ...params, ...args.filters };
    }
    setDataLoading(true);
    apiService.get(
      "/restaurants/getRestaurantsByAdmin",
      params,
      ({ err, data }) => {
        if (err) return console.log(err);
        setRestaurantList(data.data.rows);
        setTotal(data.data.count);
        setDataLoading(false);
        setisFilter(false);
      }
    );
  };
  const resetFilters = () => {
    setAppliedFilters({
      search: "",
      start_date: "",
      end_date: "",
      promoName: "",
    });
    handleSerach({
      filters: {
        search: "",
        start_date: "",
        end_date: "",
        promoName: "",
      },
    });
  };

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    setIsEdited(true);
    const rawContent = convertToRaw(newState.getCurrentContent());
    console.log(rawContent, "rawContentrawContent", newState);
    const htmlContent = draftToHtml(rawContent);
    setValue("message", htmlContent); // Update the form field with the HTML content
  };

  useEffect(() => {
    handleSerach({
      filters: appliedFilters,
    });
  }, [refresh, currentPage]);

  const lastPage = Math.ceil(total / REACT_APP_LIMIT);
  console.log(lastPage, "lastpage");
  const nextPage = () => {
    if (currentPage < lastPage) {
      updateData(currentPage + 1);
    }
  };

  const previousPage = () => {
    updateData(currentPage - 1);
  };
  const handleAddRestaurant = () => {
    // Navigate to the desired URL
    window.open(
      "https://restaurant.whatsonlogin.com/register-by-admin",
      "_blank"
    );
    // Optionally, you can also set the current tab state here if you're managing tabs
  };
  const handleLogin = (user) => {
    // Navigate to the desired URL
    // Optionally, you can also set the current tab state here if you're managing tabs
    let auth = btoa(user.username + "|" + user.plainpassword + "|" + "Admin");
    window.open(
      `https://restaurant.whatsonlogin.com?cpanel&token=${auth}`,
      "_blank"
    );
  };
  console.log(modal, "modal 123");

  const getRestaurantEmail = useCallback(
    debounce((e) => {
      const email = e.target.value.trim();
      if (!email) {
        setRestaurantEmail(true); // Reset to true if input is empty
        return;
      }

      const restaurantEmail = modal?.data?.restaurant_email;
      setDefaultContent(`
     <p><b>Hey ${modal?.data?.restaurant_name}! </b> One more step before you claim your account...</p>
      <p>Here are the details you need to log in to your restaurant dashboard:</p>
  
       <p> 1. Navigate to: https://restaurant.whatsonlogin.com/</p>
        
       <p> 2. Use your temporary login details below:</p>
          <br/> 
            <b>Username:</b> ${modal?.data?.user.username}
          <br/>
            <b>Password:</b> ${modal?.data?.user.plainpassword}
          <br/><br/>
     
         
       <p> 3. Navigate to your profile to change your username and password</p>
      <p> 4. Activate your subscription to unlock all premium features</p>
     
      <p>
        We’re thrilled to have you on board and can’t wait for you to experience all the ways WhatsOn helps you connect with customers and grow your business. Welcome to the future of customer engagement!
      </p>`);
        
      if (email !== restaurantEmail) {
        apiService.get(
          "/get-restaurant",
          { restaurant_email: email, email_verified: 1 },
          ({ err, data }) => {
            if (data) {
              setRestaurantEmail(false); // Email already exists
            } else {
              setRestaurantEmail(true); // Email is available
            }
          }
        );
      }
    }, 200),
    [modal] // Add modal as a dependency
  );

  const handleTransfer = (values) => {
    console.log(values, "ffgfgfgfgfgf");

    apiService.post(
      `/transfer-account?id=${modal?.data?.id}&&email=${modal?.data?.restaurant_email}`,
      values,
      ({ err, data }) => {
        if (err) return console.log(err);
      }
    );
    handleClose();
    setTimeout(() => {
      handleSerach();
    }, 2000); // 2000ms = 2 seconds
  };
  const communicationForm = (
    <div id="add-user-form">
      <form onSubmit={handleSubmit(handleTransfer)}>
        <div className="custom-pop-body">
          {/* <h4 className="pop-title text-center">{modal.title || ""}</h4> */}
          <div className="pop-form">
            <div className="mb-3">
              <label>Restaurant Email </label>
              <input
                maxLength={150}
                {...register("restaurant.restaurant_email", {
                  required: "Restaurant Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },

                  // onChange: getRestaurantEmail,
                })}
                onInput={(e) => getRestaurantEmail(e)} // Add explicit onInput
                type="email"
                id="email"
                className="form-control"
                placeholder="email@address.com"
              />
              {!restaurantEmail ? (
                <span className="error password-error">
                  Email Already In Use..
                </span>
              ) : (
                ""
              )}

              {errors.restaurant?.restaurant_email && (
                <span className="error password-error">
                  {errors.restaurant?.restaurant_email?.message}
                </span>
              )}
            </div>

            {/* <input {...register('restaurant_id', { required: 'This field is required', value: restaurant?.id })} type="hidden" className="form-control" /> */}
          </div>
        </div>
        <div className="col-sm-6">
          <label>Message </label>
          <div className="dashboard-auto-wrapper mb-4 sandwich-board-box ">
            <span>
              <div className="wrapper-head sandwich-board"></div>
              <div className="row">
                <div className="col-md-12">
                  <div className={`form-group mb-4`}>
                    {/*	  <label className="form-label">Description(255 characters)</label>*/}
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "colorPicker",
                          "emoji",
                        ],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        fontSize: {
                          options: [16, 18, 24],
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </span>
            {/* <div class="sandwichboard-lis text-cen">
						<img src={sandwichlist}/>
						</div> */}
          </div>
        </div>

        <div className="custom-pop-footer">
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-dark btn-long"
              // onClick={handleClose}
              disabled={restaurantEmail ? "" : "true"}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      <div className="dashboard-top-btn">
        <div className="back-redirect">
          <button
            className="btn btn-default bg-dark filter-btn text-light"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
        <div className="dashboard-top-btn justify-content-end">
          <div className="table-filter">
            <button onClick={showFilter} className="btn btn-default filter-btn">
              <img alt="" src={Filter} />
              Filters
            </button>
          </div>
          <button className="btn btn-dark" onClick={handleAddRestaurant}>
            <FontAwesomeIcon icon={faPencilAlt} /> Add Restaurant
          </button>
        </div>
      </div>
      <div className="table-sec common-table margin-t30">
        <div className="table-responsive">
          <table className="table table-borderless ">
            <thead>
              <tr>
                <th>Restaurant Name/Email</th>
                <th>Contact no</th>
                <th>Whatson_id</th>
                <th>Register Date</th>
                <th>Action</th>
                <th>Account transfer</th>
                <th>View</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {dataLoading === true ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <Spinner />
                  </td>
                </tr>
              ) : restaurantList?.length ? (
                restaurantList?.map((item, index) => (
                  <tr>
                    <td>
                      {item.restaurant_name}
                      <br />
                      {item.restaurant_email}
                    </td>

                    <td>{item.restaurant_phone_number}</td>
                    <td>{item.id} </td>
                    <td>{moment(item.created_at).format("DD-MMM, YYYY")}</td>
                    {
                      item.cancel_subscription == 0 ?   <td>
                      {" "}
                      <Link
                        // to={`/accounts/activeview-restaurants?id=${item.id}`}

                        onClick={() => handleLogin(item.user)}
                      >
                        CPanel
                      </Link>{" "}
                    </td>:<td></td>
                    }
                  
{
     item.cancel_subscription == 0 ?    <td>
     {" "}
     <Link
       // to={`/accounts/activeview-restaurants?id=${item.id}`}

       onClick={() => handleShow({ data: item })}
     >
       Transfer
     </Link>{" "}
   </td> :<td></td>
}
                 
                    <td>
                      <Link
                        to={`/accounts/activeview-restaurants?id=${item.id}`}
                      >
                        View
                      </Link>
                    </td>
                    {/* <td className="table-action">
                                                    <button
                                                        onClick={() => handleShow({
                                                            data: { id: item.id },
                                                            title: 'Suspend Restaurant - ' + item.restaurant_name,
                                                            message: 'Are you sure you want to suspend this restaurant account?',
                                                            type: 'suspend'
                                                        })}
                                                        class="btn btn-danger btn-suspend">Suspend</button>
                                                </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    <p>No Restaurants Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isFilter === true ? (
        <>
          <div className="popup-overlay show"></div>
          <div id="filter-pop" className="show">
            <div className="filter-pop-inner">
              <div className="filter-head">
                <h4>Filters</h4>
                <button className="times-btn filter-close" onClick={showFilter}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="filter-body">
                <div className="mb-4">
                  <div className="input-icon">
                    <span className="input-icon-alt">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                    <input
                      value={appliedFilters.search}
                      onChange={(e) =>
                        setAppliedFilters({
                          ...appliedFilters,
                          search: e.target.value,
                        })
                      }
                      type="text"
                      className="form-control"
                      placeholder="Type here..."
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="input-icon">
                      <span className="input-icon-alt">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <input
                        value={appliedFilters.start_date}
                        onChange={(e) =>
                          setAppliedFilters({
                            ...appliedFilters,
                            start_date: e.target.value,
                          })
                        }
                        type="date"
                        className="form-control"
                        placeholder="Start Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-icon">
                      <span className="input-icon-alt">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <input
                        value={appliedFilters.end_date}
                        onChange={(e) =>
                          setAppliedFilters({
                            ...appliedFilters,
                            end_date: e.target.value,
                          })
                        }
                        type="date"
                        className="form-control"
                        placeholder="End Date"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label className="form-label">Promo code</label>
                    <select
                      value={appliedFilters.promoName}
                      className="form-control"
                      name="foodCat"
                      onChange={(e) =>
                        setAppliedFilters({
                          ...appliedFilters,
                          promoName: e.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        Select
                      </option>
                      {promo?.length ? (
                        promo?.map((container, i) => (
                          <option key={i} value={container.promoCode}>
                            {container.promoCode}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                </div> */}
              </div>
              <div className="filter-form-btns">
                <button
                  className="btn btn-dark full-width-btn add-couple"
                  onClick={() =>
                    handleSerach({
                      filters: appliedFilters,
                    })
                  }
                >
                  Apply Filter
                </button>
                <button
                  className="btn btn-light full-width-btn"
                  onClick={resetFilters}
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal
            show={show}
            className="common-pop popup-medium"
            onHide={handleClose}
          >
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <div className="custom-pop-inner">
              <div className="custom-pop-box">
                {communicationForm}
                {/* {deleteCommunicationForm} */}
              </div>
            </div>
          </Modal>
          {lastPage > 1 ? (
            <>
              {
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={previousPage}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: lastPage }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => updateData(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === lastPage ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={nextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              }
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default AllAccounts;
